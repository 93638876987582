<template>
  <div>
    <v-list
      two-line
      subheader
    >
      <v-subheader>MATLAB </v-subheader>

      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-code-greater-than</v-icon>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>MATLAB</v-list-tile-title>
          <v-list-tile-sub-title>Execute code to generate plot</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-btn
            icon
            ripple
          >
            <v-icon
              @click="writeCodeToClipboard(matlabCode)"
            >
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>

    <v-card-text
      v-if="writeRun"
      class="pt-0 mt-0"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="selectedItem"
              :disabled="isUpdating"
              :items="items"
              color="blue-grey lighten-2"
              label="Select spectrum"
              item-text="id"
              item-value="contribution"
            >
              <template #selection="data">
                <span v-if="data.item.current_min !== data.item.current_max && data.item.current_shape === 'dc'">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.current_min !== data.item.current_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA, ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.pulses_min !== data.item.pulses_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.pulses_min + ' - ' + data.item.pulses_max + '/s, ' + data.item.duty_min + '%, ' + data.item.current_min + ' mA (' + data.item.pulse_type + ', ' + data.item.pulse_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.duty_min !== data.item.duty_max">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' - ' + data.item.duty_max + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA (' + data.item.duty_type + ', ' + data.item.duty_steps + ' steps)'" />
                </span>
                <span v-else-if="data.item.current_shape === 'dc'">
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' mA'" />
                </span>
                <span v-else>
                  <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA'" />
                </span>
              </template>
              <template #item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item" />
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <span v-if="data.item.current_min !== data.item.current_max && data.item.current_shape === 'dc'">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.current_min !== data.item.current_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.current_min + ' - ' + data.item.current_max + ' mA, ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, (' + data.item.current_type + ', ' + data.item.current_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.pulses_min !== data.item.pulses_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.pulses_min + ' - ' + data.item.pulses_max + '/s, ' + data.item.duty_min + '%, ' + data.item.current_min + ' mA (' + data.item.pulse_type + ', ' + data.item.pulse_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.duty_min !== data.item.duty_max">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' - ' + data.item.duty_max + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA (' + data.item.duty_type + ', ' + data.item.duty_steps + ' steps)'" />
                    </span>
                    <span v-else-if="data.item.current_shape === 'dc'">
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + ' mA'" />
                    </span>
                    <span v-else>
                      <v-list-tile-title v-html="data.item.temperature_min + '°C ' + data.item.current_shape + ': ' + data.item.duty_min + '%, ' + data.item.pulses_min + '/s, ' + data.item.current_min + ' mA'" />
                    </span>
                    <v-list-tile-sub-title
                      v-if="data.item.flag === 'ok'"
                      class="green--text darken-1"
                      v-html="timeStringConv(data.item.timestamp)"
                    />
                    <div v-else>
                      <v-list-tile-sub-title
                        v-if="data.item.comment"
                        class="amber--text darken-1"
                        v-html="timeStringConv(data.item.timestamp) + ': ' + data.item.comment"
                      />
                      <v-list-tile-sub-title
                        v-else
                        class="amber--text darken-1"
                        v-html="timeStringConv(data.item.timestamp)"
                      />
                    </div>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            v-if="selectedObject.contributionfile"
            cols="12"
          >
            <object
              class="gnuplotSVG"
              :data="'https://cmsdb.darkcosmos.org/files/viewplot.php?file='+selectedObject.contributionfile+'&version=svg'+fetchSuffix"
            />
            <div
              v-if="selectedObject.flag === 'ok'"
              class="green--text darken-1"
              v-html="timeStringConv(selectedObject.timestamp)"
            />
            <div v-else>
              <div
                v-if="selectedObject.comment"
                class="amber--text darken-1"
                v-html="timeStringConv(selectedObject.timestamp) + ': ' + selectedObject.comment"
              />
              <div
                v-else
                class="amber--text darken-1"
                v-html="timeStringConv(selectedObject.timestamp)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <pre
        v-if="donecustom && selectedItem !== undefined && selectedItem !== ''"
        v-highlightjs
      ><code class="matlab">{{ matlabCode }}</code></pre>
    </v-card-text>
  </div>
</template>

<script>
import moment from 'moment'
import { EventBus } from '../../scripts/event_bus'

import { mapGetters } from 'vuex'
export default {
  name: 'CorrectedIVP',

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    led: {
      type: Object,
      required: true,
      default: () => ({})
    },
    custom: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data () {
    return {
      fetchSuffix: '',
      donecustom: false,
      isUpdating: false,
      matlabCode: '',
      writeRun: false,
      items: [],
      spectra: {},
      selectedItem: '',
      params: '',
      type: '',
      selectedObject: {},
      SpecAOs: {},
      ContribAOs: {},
      etchSuffix: ''
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        const self = this
        setTimeout(function () {
          self.selectedItem = self.data.files[0].selection // update value for dropdown based on selection (if given)
        }, 1000)

        this.getSpectra()
        this.updateMatlab()
      },
      deep: true
    },
    custom: {
      handler: function (newValue) {
        const self = this
        setTimeout(function () {
          self.selectedItem = self.data.files[0].selection // update value for dropdown based on selection (if given)
        }, 1000)

        this.updateMatlab()
      },
      deep: true
    },
    selectedItem: function () {
      const self = this
      this.items.forEach(function (item) {
        if (item.contribution === self.selectedItem) {
          self.selectedObject = item
        }
      })
      this.updateMatlab()
    }
  },
  mounted () {
    if (localStorage.getItem('devAPIKey')) {
      this.fetchSuffix = '&apikey=' + localStorage.getItem('devAPIKey')
    }

    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)
    this.getSpectra()
  },
  methods: {
    writeCodeToClipboard: function (codeToCopy) {
      console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    updateMatlab: function () {
      this.donecustom = false
      this.getPDparams()
      this.getPDtype()
      this.getSpecAOs()
    },
    getPDparams: function () {
      const self = this
      this.custom.forEach(function (item) {
        if (item.field === 'Photodiode') {
          self.params = item.params
        }
      })
    },
    getPDtype: function () {
      const self = this
      this.custom.forEach(function (item) {
        if (item.field === 'Photodiode') {
          self.type = item.type
        }
      })
    },
    timeStringConv: function (timestamp) {
      const rightNow = moment()

      return moment.unix(timestamp).calendar(rightNow)
    },
    getSpecAOs: function () {
      if (this.selectedObject.id > 0) {
        this.$root.$data.si.getResultAOs([
          { key: 'coll1', value: this.selectedObject.spectra },
          { key: 'coll2', value: this.selectedObject.contribution },
          { key: 'fileid', value: this.selectedObject.id }
        ], (response) => {
          this.SpecAOs = []
          if (response.aos1) {
            this.SpecAOs = response.aos1
          }

          this.ContribAOs = []
          if (response.aos2) {
            this.ContribAOs = response.aos2
          }

          console.log('got', this.SpecAOs)
          this.writeMatlab()
          this.donecustom = true
        },
        (error) => {
          this.writeMatlab()
          this.donecustom = true
          console.log(error)
        })
      } else {
        this.writeMatlab()
        this.donecustom = true
      }
    },
    getSpectra: function () {
      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.getSpectra([
          { key: 'led_id', value: this.led.id },
          { key: 'exp_id', value: this.data.id }

        ], resp => {
          this.spectra = resp
          const self = this
          if (this.data.files[0].filetypeid === '13') {
            this.spectra.spectra.forEach(function (element) {
              if (self.data.temperature_min === element.temperature_min && self.data.current_shape === element.current_shape && element.current_type === 'linramp' && element.pulse_type === 'constant' && element.duty_type === 'constant' && self.data.duty_min === element.duty_min) {
                self.items.push(element)
              }
            })
          } else {
            this.spectra.spectra.forEach(function (element) {
              if (self.data.temperature_min === element.temperature_min && self.data.current_shape === element.current_shape && self.data.current_type === element.current_type && self.data.duty_type === element.duty_type && self.data.pulse_type === element.pulse_type) {
                self.items.push(element)
              }
            })
          }
          this.loading = false
        })
      }
    },
    writeMatlab: function () {
      this.writeRun = false
      const dbs = []
      const aos = []
      let fileid = 0
      let collid = 0

      if (this.data.files) {
        if (this.data.files[0]) {
          fileid = this.data.files[0].fileid
          collid = this.data.files[0].colluuid
          if (this.data.files[0].aos) {
            this.data.files[0].aos.forEach(function (element) {
              aos.push("'" + element.ao_id + "'")
              dbs.push("'" + element.ltpda + "'")
            })
          }
        }
      }

      const specdbs = []
      const specaos = []

      if (this.SpecAOs[0]) {
        this.SpecAOs.forEach(function (element) {
          specaos.push("'" + element.ao_id + "'")
          specdbs.push("'norm_spectra'")
        })
      }

      const contribdbs = []
      const contribaos = []

      if (this.ContribAOs[0]) {
        this.ContribAOs.forEach(function (element) {
          contribaos.push("'" + element.ao_id + "'")
          contribdbs.push("'uv_contributions'")
        })
      }

      console.log('used', this.SpecAOs)

      let nmmin = 0
      let nmmax = 0

      if (this.led.lotid === '25' && this.led.manid === '13') { // Crystal IS A019C1
        nmmin = 235
        nmmax = 280
      } else if (this.led.lotid === '23' && this.led.manid === '13') { // Crystal IS A017A1
        nmmin = 237
        nmmax = 282
      } else if (this.led.lotid === '27' && this.led.manid === '15') { // SETi TUD59H1B
        nmmin = 245
        nmmax = 290
      } else if (this.led.lotid === '29' && this.led.manid === '15') { // SETi TUD49H1B
        nmmin = 228
        nmmax = 273
      } else if (this.led.lotid === '31' || this.led.lotid === '35' || this.led.lotid === '37') { // Crystal IS UA2001-1-1/2/3
        nmmin = 237
        nmmax = 282
      }

      // current settings

      let ramp = ''
      let variable = ''
      let xlabel = ''
      let xunit = ''
      let set = ''

      if (this.selectedObject.current_type !== 'constant') {
        if (this.selectedObject.current_shape === 'pulse') {
          variable = 'LED drive current set voltage [V]'
          xlabel = 'LED drive current set voltage'
          xunit = 'V'
          set = '5'
          if (this.selectedObject.current_type === 'linramp') {
            ramp = 'linspace(' + this.selectedObject.current_min + ',' + this.selectedObject.current_max + ',' + this.selectedObject.current_steps + ')'
          } else if (this.selectedObject.current_type === 'logramp') {
            ramp = 'logspace(log10(' + this.selectedObject.current_min + '),log10(' + this.selectedObject.current_max + '),' + this.selectedObject.current_steps + ')'
          }
        } else {
          variable = 'LED drive current [A]'
          xlabel = 'LED drive current'
          xunit = 'A'
          set = '5'
          if (this.selectedObject.current_type === 'linramp') {
            ramp = 'linspace(' + this.selectedObject.current_min / 1000 + ',' + this.selectedObject.current_max / 1000 + ',' + this.selectedObject.current_steps + ')'
          } else if (this.selectedObject.current_type === 'logramp') {
            ramp = 'logspace(log10(' + this.selectedObject.current_min / 1000 + '),log10(' + this.selectedObject.current_max / 1000 + '),' + this.selectedObject.current_steps + ')'
          }
        }
      } else if (this.selectedObject.pulse_type !== 'constant') {
        variable = 'Pulses per ' + this.data.pulse_frequency + ' seconds'
        xlabel = 'Pulses per ' + this.data.pulse_frequency + ' seconds'
        xunit = '1/s'
        set = '7'
        if (this.selectedObject.pulse_type === 'linramp') {
          ramp = 'linspace(' + this.selectedObject.pulses_min + ',' + this.selectedObject.pulses_max + ',' + this.selectedObject.pulse_steps + '); x = round(x)'
        } else if (this.selectedObject.pulse_type === 'logramp') {
          ramp = 'logspace(log10(' + this.selectedObject.pulses_min + '),log10(' + this.selectedObject.pulses_max + '),' + this.selectedObject.pulse_steps + '); x = round(x)'
        }
      } else if (this.selectedObject.duty_type !== 'constant') {
        variable = 'Duty cycle [%]'
        xlabel = 'Duty cycle [%]'
        xunit = '1'
        set = '7'
        if (this.selectedObject.duty_type === 'linramp') {
          ramp = 'linspace(' + this.selectedObject.duty_min + ',' + this.selectedObject.duty_max + ',' + this.selectedObject.duty_steps + '); x = round(x)'
        } else if (this.selectedObject.duty_type === 'logramp') {
          ramp = 'logspace(log10(' + this.selectedObject.duty_min + '),log10(' + this.selectedObject.duty_max + '),' + this.selectedObject.duty_steps + '); x = round(x)'
        }
      }

      let coll1 = ''
      let coll2 = ''

      if (this.data.files) {
        if (this.data.files[0].filetypeid === '3') {
          coll1 = 'realpower_ivp'
          coll2 = 'electronyield'
        } else if (this.data.files[0].filetypeid === '13') {
          coll1 = 'realpower_pulses'
          coll2 = 'electronyield_pulses'
        } else if (this.data.files[0].filetypeid === '15') {
          coll1 = 'realpower_duty'
          coll2 = 'electronyield_duty'
        }
      }

      let translator = 'IIao = 10.^(a.*(ipvIntao+b));'
      let translatorComment = '% I = 10^(a*(U+b))'

      if (this.type === 'linear') {
        translator = 'IIao = a.*ipvIntao+b;'
        translatorComment = '% I = a*U+b'
      }

      this.matlabCode = 'clear all;\nclose all;\n\n' +
        '% Get IVP data\n' +
          'db = {' + dbs.join(', ') + '};\n' +
          'uuids = {' + aos.join(', ') + '};\n' +
          'ipv = [];\n' +
          'for ii = 1:numel(uuids)\n' +
          'ipv = [ipv ao(plist(\'hostname\', \'localhost\', ...\n' +
          this.data.ltpda +
          '\'database\', db{ii}, ...\n' +
          '\'uuid\', uuids{ii}))];\n' +
          'end\n' +
          'ipv(' + set + ').setXaxisName(\'' + xlabel + '\');\n' +
          'ipv(' + set + ').setYaxisName(\'ADC Voltage\');\n' +
          '\n' +
          '% Interpolation to match spectral data\n' +
          'x = ' + ramp + ';\n' +
          'ipvInt = interp1(ipv(' + set + ').data.x, [ipv(' + set + ').data.x ipv(' + set + ').data.y], x,\'spline\');\n' +
          'ipvIntao = ao(plist(\'yvals\', ipvInt(:,2), \'xvals\', ipvInt(:,1), \'name\', [ipv(' + set + ').name \' (interpolated)\']));\n' +
          'ipvIntao.setXunits(ipv(' + set + ').xunits);\n' +
          'ipvIntao.setYunits(ipv(' + set + ').yunits);\n' +
        'ipvIntao.setXaxisName(ipv(' + set + ').xaxisname);\n' +
        'ipvIntao.setYaxisName(ipv(' + set + ').yaxisname);\n' +
          'iplot(ipv(' + set + '), ipvIntao);\n' +
          'set(gca, \'XScale\', \'log\');\n' +
          'set(gca, \'YScale\', \'log\');\n' +
          '\n' +
          '% Based on the ADC voltage, what is the total photocurrent (use approprate calibration values for PD channel)\n' +
          translatorComment + '\n' +
          this.params + '\n' +
          translator + '\n' +
          'IIao.setYunits(\'A\');\n' +
          'IIao.setName(ipv(' + set + ').name);\n' +
          'IIao.setXaxisName(\'' + xlabel + '\');\n' +
          'IIao.setYaxisName(\'Photocurrent\');\n' +
          'iplot(IIao)\n' +
          '\n' +
          '% Get Spectra\n' +
          'db = {' + specdbs.join(', ') + '};\n' +
          'uuids = {' + specaos.join(', ') + '};\n' +
          'aos = [];\n' +
          'for ii = 1:numel(uuids)\n' +
          'aos = [aos ao(plist(\'hostname\', \'localhost\', ...\n' +
          this.data.ltpda +
          '\'database\', db{ii}, ...\n' +
          '\'uuid\', uuids{ii}))];\n' +
          'end\n' +
          '\n' +
          'for i = 1:numel(aos)\n' +
          '\taos(i).setYunits(\'\');\n' +
          'end\n' +
          'aos.setXaxisName(\'Wavelength\');\n' +
          'aos.setYaxisName(\'Normalized power\');\n' +
          'iplot(aos);\n' +
          '\n' +
          '% Responsivity Hamamatsu S1337-1010BQ \n' +
          'pathX = [184.379 201.315 218.251 232.544 239.499 246.927 253.718 258.107 267.598 272.769 278.923 285.698 291.51 300.289 306.377 315.365 324.111 330.16 337.41 345.812 351.373 364.341 375.98 380.032 391.199 399.796 427.005 507.204 572.238 620.973 691.573 786.265 873.775 916.139 940.013 949.996 959.563 968.695 979.249 987.573 999.496 1012.083 1016.168 1021.47 1030.296 1035.934 1040.025 1044.842 1051.751 1060.04 1067.008 1074.405 1084.235 1090.277 1097.306 1104.335];\n' +
          'pathXY = [184.379 0.129743; 201.315 0.133039; 218.251 0.136335; 232.544 0.137975; 239.499 0.136075; 246.927 0.131253; 253.718 0.123572; 258.107 0.116782; 267.598 0.102628; 272.769 0.100113; 278.923 0.101502; 285.698 0.106631; 291.51 0.113782; 300.289 0.124808; 306.377 0.129943; 315.365 0.136023; 324.111 0.140446; 330.16 0.143609; 337.41 0.149464; 345.812 0.157324; 351.373 0.158655; 364.341 0.159566; 375.98 0.16226; 380.032 0.165705; 391.199 0.175857; 399.796 0.183254; 427.005 0.20366; 507.204 0.261574; 572.238 0.305453; 620.973 0.335502; 691.573 0.378262; 786.265 0.432175; 873.775 0.475675; 916.139 0.492001; 940.013 0.497915; 949.996 0.498873; 959.563 0.498; 968.695 0.494299; 979.249 0.485916; 987.573 0.475677; 999.496 0.454297; 1012.083 0.421145; 1016.168 0.407619; 1021.47 0.387866; 1030.296 0.35; 1035.934 0.324693; 1040.025 0.308714; 1044.842 0.291728; 1051.751 0.26456; 1060.04 0.228509; 1067.008 0.199247; 1074.405 0.174779; 1084.235 0.154344; 1090.277 0.146799; 1097.306 0.141187; 1104.335 0.135575];\n' +
          '%interpolate to match spectrum x values\n' +
          'pathSteps = aos(1).data.x;\n' +
          'HamResponsitivity = interp1(pathX, pathXY, pathSteps,\'spline\');\n' +
          'R_ham = ao(plist(\'yvals\', HamResponsitivity(:,2,:), \'xvals\', HamResponsitivity(:,1,:), \'name\', \'Hamamatsu S1337-1010BQ\'));\n' +
          'R_ham.setYunits(\'A/W\');\n' +
          'R_ham.setXunits(\'nm\');\n' +
          '\n' +
          '% Calculate current spectra\n' +
          'normCurrentSpectra = [];\n' +
          'realCurrentSpectra = [];\n' +
          'for i = 1:numel(aos) %foreach PowerSpectra\n' +
          '    nCurrentSpectra = aos(i) .* R_ham.data.y / sum(aos(i).data.y .* R_ham.data.y);\n' +
          '    normCurrentSpectra = [normCurrentSpectra nCurrentSpectra];\n' +
          '    rCurrentSpectra = nCurrentSpectra * IIao.data.y(i);\n' +
          '    realCurrentSpectra = [realCurrentSpectra rCurrentSpectra];\n' +
          'end\n' +
          'for i = 1:numel(realCurrentSpectra)\n' +
          '\trealCurrentSpectra(i).setYunits(\'\');\n' +
          'end\n' +
          'pl = plist(\'Legends\',\'off\',\'YLABELS\',\'Photocurrent\',\'XLABELS\',\'Wavelength\');\n' +
          'iplot(realCurrentSpectra,pl);\n' +
          '\n' +
          '% real IP curve\n' +
          'x = ' + ramp + ';\n' +
          'y = [];\n' +
          'realPowerSpectra = [];\n' +
          'for i = 1:numel(realCurrentSpectra)\n' +
          '    realPowerSpectra = [realPowerSpectra realCurrentSpectra(i) ./ R_ham.data.y];\n' +
          '    y = [y sum(realPowerSpectra(i).data.y)];\n' +
          'end\n' +
          'IPao = ao(plist(\'xvals\', x, \'yvals\', y, \'xunits\', \'' + xunit + '\', \'yunits\', \'W\', \'name\', \'' + this.led.internal_serialnumber + ' (actual light power)\', \'description\', \'full incident light power\'));\n' +
          'IPao.setXaxisName(\'' + xlabel + '\');\n' +
          'IPao.setYaxisName(\'Light power\');\n' +
          '\n' +
          '% Get Contributions\n' +
          'db = {' + contribdbs.join(', ') + '};\n' +
          'uuids = {' + contribaos.join(', ') + '};\n' +
          'con = [];\n' +
          'for ii = 1:numel(uuids)\n' +
          'con = [con ao(plist(\'hostname\', \'localhost\', ...\n' +
          this.data.ltpda +
          '\'database\', db{ii}, ...\n' +
          '\'uuid\', uuids{ii}))];\n' +
          'end\n' +
          'for i = 1:numel(con)\n' +
          '\tcon(i).setYunits(\'\');\n' +
          'end\n' +
          'con.setXaxisName(\'' + xlabel + '\');\n' +
          'con.setYaxisName(\'Normalized power\');\n' +
          'iplot(con);\n' +
          '\n' +
          '%plot real IVP\n' +
          'UVIPao = IPao.*con(:,1).y;\n' +
          'UVIPao.setName(\'' + this.led.internal_serialnumber + ': ' + nmmin + '-' + nmmax + ' nm contribution\');\n' +
          'sub254IPao = IPao.*con(:,2).y;\n' +
          'sub254IPao.setName(\'' + this.led.internal_serialnumber + ': < 254 nm contribution\');\n' +
          'iplot(IPao,UVIPao,sub254IPao);\n' +
          'set(gca, \'XScale\', \'log\');\n' +
          'set(gca, \'YScale\', \'log\');\n' +
          '\n' +
      '% create data file\n' +
      'fileID = fopen(\'xy.txt\', \'w\');\n' +
      '\n' +
      '%write key labels\n' +
      '    fprintf(fileID, \'"%s" \', \'' + variable + '\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (actual light power)\');\n' +
      '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': ' + nmmin + '-' + nmmax + ' nm contribution\');\n' +
        '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ': < 254 nm contribution\');\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      '   \n' +
      'for i = 1:numel(x)\n' +
      '    fprintf(fileID, \'%e \', x(i));\n' +
      '    fprintf(fileID, \'%e \', IPao.data.y(i));\n' +
          '    fprintf(fileID, \'%e \', UVIPao.data.y(i));\n' +
        '    fprintf(fileID, \'%e \', sub254IPao.data.y(i));\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      'end\n' +
      'fclose(fileID);\n' +
      '\n' +
      '% read file content and submit to CMSdb\n' +
      'filetext = fileread(\'xy.txt\');\n' +
      'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'1\', \'' + this.selectedObject.contribution + '\', filetext, \'' + this.userData.api_key + '\')\n' +
      '\n' +
          '% upload real power IVP AOs to CMSdb\n' +
          'realpowerivp_det = submit([IPao UVIPao sub254IPao], ...\n' +
          'plist(\'hostname\', \'localhost\', ...\n' +
          '\'database\', \'' + coll1 + '\', ...\n' +
          '\'no dialog\', 1, ...\n' +
          this.data.ltpda +
          '\'experiment title\', \'' + this.led.internal_serialnumber + ': Real power IVP ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
          '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
          '\'analysis description\', \'Full light power and UV contributions (' + nmmin + '-' + nmmax + ' nm) as well as < 254 nm contributions\', ...\n' +
          '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
          ');\n' +
          'submit_aolist(realpowerivp_det, \'' + this.data.files[0].name + '\', [IPao UVIPao sub254IPao], \'A1\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n' +
          '\n' +
          '% Flux spectra\n' +
          'x = ' + ramp + ';\n' +
          'y = [];\n' +
          'fluxSpectra = realPowerSpectra .* realPowerSpectra(1).data.x .* 1e-9 ./ (ao.h.y .* ao.c.y);\n' +
          'for i = 1:numel(fluxSpectra)\n' +
          '    y = [y sum(fluxSpectra(i).data.y)];\n' +
          'end\n' +
          'Flux = ao(plist(\'xvals\', x, \'yvals\', y, \'xunits\', \'' + xunit + '\', \'yunits\', \'/s\', \'name\', \'CJ03 (4.4 eV)\', \'description\', \'dischrage efficiency (based on 4.4 eV work function, QY = 10^-5 at 254 nm)\'));\n' +
          'Flux.setXaxisName(\'' + xlabel + '\');\n' +
          'Flux.setYaxisName(\'Photon flux\');\n' +
          'iplot(Flux)\n' +
          'set(gca, \'XScale\', \'log\');\n' +
          'set(gca, \'YScale\', \'log\');\n' +
          '\n' +
          '% 4.4 eV work function\n' +
          'lam = aos(1).data.x*1e-9; % wavelength\n' +
          'Phi = 4.4; % work function in eV\n' +
          'E = ao.h.y.*ao.c.y./lam./ao.e.y; % photon energy in eV\n' +
          'E_hg = ao.h.y.*ao.c.y./254e-9./ao.e.y; % photon energy in eV at mercury lamp wavelength\n' +
          'A = 1e-5./(E_hg-Phi).^2; % normalization to set QY to 1e-5 at mercury wavelength\n' +
          'QY44 = A.*(E - Phi).^2; % calculate Quantum Yield\n' +
          'QY44(E<Phi) = 0; % set QY to zero for energy below the work function\n' +
          '\n' +
          '% 5.2 eV work function\n' +
          'lam = aos(1).data.x*1e-9; % wavelength\n' +
          'Phi = 5.2; % work function in eV\n' +
          'E = ao.h.y.*ao.c.y./lam./ao.e.y; % photon energy in eV\n' +
          'E_hg = ao.h.y.*ao.c.y./254e-9./ao.e.y; % photon energy in eV at mercury lamp wavelength\n' +
          'A = 1e-5./(E_hg-Phi).^2; % normalization to set QY to 1e-5 at mercury wavelength\n' +
          'QY52 = A.*(E - Phi).^2; % calculate Quantum Yield\n' +
          'QY52(E<Phi) = 0; % set QY to zero for energy below the work function\n' +
          '\n' +
          '% Create efficiency AOs (electron yield)\n' +
          'y = [];\n' +
          'efficiencySpectra = fluxSpectra .* QY44;\n' +
          'for i = 1:numel(efficiencySpectra)\n' +
          '    y = [y sum(efficiencySpectra(i).data.y)];\n' +
          'end\n' +
          'IP44 = ao(plist(\'xvals\', x, \'yvals\', y, \'xunits\', \'' + xunit + '\', \'yunits\', \'1/s\', \'name\', \'' + this.led.internal_serialnumber + ' (4.4 eV)\', \'description\', \'discharge efficiency (based on 4.4 eV work function, QY = 10^-5 at 254 nm)\'));\n' +
          'IP44.setXaxisName(\'' + xlabel + '\');\n' +
          'IP44.setYaxisName(\'Electron yield\');\n' +
          'y = [];\n' +
          'efficiencySpectra = fluxSpectra .* QY52;\n' +
          'for i = 1:numel(efficiencySpectra)\n' +
          '    y = [y sum(efficiencySpectra(i).data.y)];\n' +
          'end\n' +
          'IP52 = ao(plist(\'xvals\', x, \'yvals\', y, \'xunits\', \'' + xunit + '\', \'yunits\', \'1/s\', \'name\', \'' + this.led.internal_serialnumber + ' (5.2 eV)\', \'description\', \'discharge efficiency (based on 5.2 eV work function, QY = 10^-5 at 254 nm)\'));\n' +
          'IP52.setXaxisName(\'' + xlabel + '\');\n' +
          'IP52.setYaxisName(\'Electron yield\');\n' +
          'iplot(IP44, IP52);\n' +
          'set(gca, \'XScale\', \'log\');\n' +
          'set(gca, \'YScale\', \'log\');\n' +
      '\n' +
      '% create data file\n' +
      'fileID = fopen(\'xy2.txt\', \'w\');\n' +
      '\n' +
      '%write key labels\n' +
      '    fprintf(fileID, \'"%s" \', \'' + variable + '\');\n' +
      '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (4.4 eV)\');\n' +
          '    fprintf(fileID, \'"%s" \', \'' + this.led.internal_serialnumber + ' (5.2 eV)\');\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      '   \n' +
      'for i = 1:numel(x)\n' +
      '    fprintf(fileID, \'%e \', x(i));\n' +
      '    fprintf(fileID, \'%e \', IP44.data.y(i));\n' +
      '    fprintf(fileID, \'%e \', IP52.data.y(i));\n' +
      '    fprintf(fileID, \'\\n\');\n' +
      'end\n' +
      'fclose(fileID);\n' +
      '\n' +
      '% read file content and submit to CMSdb\n' +
      'filetext = fileread(\'xy2.txt\');\n' +
      'submit_gnuplot(\'' + fileid + '\', \'' + collid + '\', \'2\', \'' + this.selectedObject.contribution + '\', filetext, \'' + this.userData.api_key + '\')\n' +
      '\n' +
          '% upload electron yield AOs to CMSdb\n' +
          'electronyield_det = submit([IP44 IP52], ...\n' +
          'plist(\'hostname\', \'localhost\', ...\n' +
          '\'database\', \'' + coll2 + '\', ...\n' +
          '\'no dialog\', 1, ...\n' +
          this.data.ltpda +
          '\'experiment title\', \'' + this.led.internal_serialnumber + ': Electron yield ' + this.data.subject + ' (Collection UUID:' + this.data.files[0].colluuid + ')\', ...\n' +
          '\'experiment description\', \'' + this.data.title + ': ' + this.data.description + '\', ...\n' +
          '\'analysis description\', \'4.4 eV and 5.2 eV work functions\', ...\n' +
          '\'additional comments\', \'auto generated by CMSdb\') ...\n' +
          ');\n' +
          'submit_aolist(electronyield_det, \'' + this.data.files[0].name + '\', [IP44 IP52], \'A2\', \'' + this.data.files[0].fileid + '\', \'' + this.userData.api_key + '\')\n'
      this.writeRun = true
    }
  }
}
</script>
<style scoped>
</style>
